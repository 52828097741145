/*import React, { useRef, useEffect, useContext } from 'react';
import { AudioContext } from './audioContextProvider';

function AudioPlayerComponent({ audioSrc }) {
  const audioRef = useRef(null);
  const { audio } = useContext(AudioContext);

  useEffect(() => {
    // Initialize audioRef.current if it hasn't been initialized yet or if audioSrc changes
    if (!audioRef.current || audioRef.current.src !== audioSrc) {
      audioRef.current = new Audio(audioSrc);
    }

    // Function to play or pause the audio
    const playOrPauseAudio = () => {
      if (audio === 'PLAYING') {
        // Refresh the audio source every time before playing
        audioRef.current.src = audioSrc; // Update the audio source to refresh it
        audioRef.current.load(); // Load the new source

        // Attempt to play the audio
        const playPromise = audioRef.current.play();

        // Catch and ignore the error if play() is interrupted by a pause() call
        if (playPromise !== undefined) {
          playPromise.then(() => {
            // Playback started successfully
          }).catch(error => {
            console.log("Playback was interrupted:", error.message);
          });
        }
      } else {
        // Pause the audio
        audioRef.current.pause();
      }
    };

    // Call playOrPauseAudio whenever the audio state changes
    playOrPauseAudio();
  }, [audio, audioSrc]); // This effect depends on 'audio' state and 'audioSrc' prop changes

  return null; // This component does not render anything itself
}

export default AudioPlayerComponent;
*/
import React, { useRef, useEffect, useContext } from 'react';
import { AudioContext } from './audioContextProvider';
import debounce from 'lodash.debounce'; // Make sure to install lodash: npm install lodash.debounce

function AudioPlayerComponent({
  audioSrc,
  onStatusChange,
  maxRetries = 5,
  initialRetryDelay = 1000,
  maxRetryDelay = 30000,
}) {
  const audioRef = useRef(null);
  const retryCountRef = useRef(0);
  const retryTimeoutRef = useRef(null);
  const { audio } = useContext(AudioContext);

  useEffect(() => {
    // Initialize the audio element if it hasn't been already
    if (!audioRef.current) {
      audioRef.current = new Audio();
      audioRef.current.preload = 'metadata'; // Preload metadata for faster recovery
    }
    const audioElement = audioRef.current;

    // Function to update parent component about the audio status
    const updateStatus = (status) => {
      if (onStatusChange) {
        onStatusChange(status);
      }
    };

    // Function to handle audio errors and attempt to reconnect
    const handleAudioError = debounce(() => {
      const { error } = audioElement;
      if (error) {
        // Handle specific error codes if needed
        if (error.code === 2) { // MEDIA_ERR_NETWORK
          if (retryCountRef.current < maxRetries) {
            retryCountRef.current += 1;
            const retryDelay = Math.min(
              initialRetryDelay * 2 ** (retryCountRef.current - 1),
              maxRetryDelay
            );
            console.log(
              `Audio error occurred, retrying in ${retryDelay}ms (Attempt ${retryCountRef.current}/${maxRetries})...`
            );

            // Clear any existing retry timeout
            if (retryTimeoutRef.current) {
              clearTimeout(retryTimeoutRef.current);
            }

            // Set a timeout to retry playing the audio
            retryTimeoutRef.current = setTimeout(() => {
              audioElement.load(); // Reload the audio source
              audioElement.addEventListener('canplay', retryPlay);
            }, retryDelay);
          } else {
            console.log('Max retries reached. Unable to recover audio playback.');
            updateStatus('ERROR');
          }
        } else {
          console.log('Non-recoverable audio error occurred.');
          updateStatus('ERROR');
        }
      }
    }, 1000); // Debounce interval of 1 second to prevent rapid retries

    // Function to attempt playback after retrying
    const retryPlay = () => {
      audioElement.removeEventListener('canplay', retryPlay);
      const playPromise = audioElement.play();
      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            console.log('Playback resumed after retry.');
            updateStatus('PLAYING');
          })
          .catch((error) => {
            console.log('Playback failed after retry:', error.message);
            updateStatus('ERROR');
          });
      }
    };

    // Event listeners for audio status updates
    const handlePlaying = () => updateStatus('PLAYING');
    const handlePause = () => updateStatus('PAUSED');

    // Add event listeners
    audioElement.addEventListener('error', handleAudioError);
    audioElement.addEventListener('stalled', handleAudioError);
    audioElement.addEventListener('waiting', handleAudioError);
    audioElement.addEventListener('playing', handlePlaying);
    audioElement.addEventListener('pause', handlePause);

    // Update audio source if it has changed
    if (audioElement.src !== audioSrc) {
      audioElement.src = audioSrc;
      retryCountRef.current = 0; // Reset retry count when source changes
    }

    // Play or pause the audio based on the 'audio' state
    if (audio === 'PLAYING') {
      const playPromise = audioElement.play();
      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            console.log('Playback started successfully.');
            updateStatus('PLAYING');
          })
          .catch((error) => {
            console.log('Playback failed:', error.message);
            updateStatus('ERROR');
          });
      }
    } else {
      audioElement.pause();
    }

    // Cleanup function to remove event listeners and clear timeouts
    return () => {
      audioElement.removeEventListener('error', handleAudioError);
      audioElement.removeEventListener('stalled', handleAudioError);
      audioElement.removeEventListener('waiting', handleAudioError);
      audioElement.removeEventListener('playing', handlePlaying);
      audioElement.removeEventListener('pause', handlePause);

      if (retryTimeoutRef.current) {
        clearTimeout(retryTimeoutRef.current);
      }

      audioElement.pause();
      audioElement.src = '';
    };
  }, [
    audio,
    audioSrc,
    maxRetries,
    initialRetryDelay,
    maxRetryDelay,
    onStatusChange,
  ]); // Include all dependencies

  return null; // This component does not render any UI
}

export default AudioPlayerComponent;
